import React from 'react'
import { BottomRosemaryImg} from './footerStyles.js'

const Footer = () => {
  return (
    <div>
        <BottomRosemaryImg src='../../media/bottom-rosemary.png'/>
    </div>
  )
}

export default Footer
import styled from "styled-components";

export const Container = styled.div`
margin:2vh 15vh;

input,select{
    width:50%;
}
select{
    margin-top:2vh;
    height:35px;
}
button{
    width:30%;
    text-align:centre
}
`

import styled from "styled-components"

export const BottomRosemaryImg =styled.img`
width:20%;
position:absolute;
bottom:0;
right:40vh;
@media (max-width: 768px) {
    display: none;
  }
`